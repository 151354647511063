import { Link } from "react-scroll";
import React, { useEffect, useState } from "react";
import "./header.css";
import { data, subheader } from "../const/data";
import logo from "../../asset/wings.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseCircle } from "react-icons/io5";

const Header = React.memo(() => {
  const navigate = useNavigate();
  const [bgColors, setBgColors] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setBgColors(true);
      } else {
        setBgColors(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [path, setPath] = useState("/");
  const { pathname } = useLocation();
  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  const [show, setShow] = useState(false);

  return (
    <>
      <header className={`header ${bgColors ? `darkblue` : `orange`}`}>
        <img
          src={logo}
          alt="veerawebtech"
          className="logo"
          onClick={() => navigate("/")}
        />
        <div className="mob-sec">
          {show ? (
            <IoCloseCircle
              className="mobile-icon"
              onClick={() => setShow(false)}
            />
          ) : (
            <GiHamburgerMenu
              className="mobile-icon"
              onClick={() => setShow(true)}
            />
          )}
        </div>
        <div className={`nav-container ${show ? "mob-nav" : "mob-nav-close"}`}>
          {path === "/" && (
            <nav>
              {data.map((item, i) => {
                return (
                  <Link
                    activeClass="active"
                    to={item.path}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    key={i}
                    className="headlinks"
                    onClick={
                      item.path === "contact"
                        ? () => {
                            navigate("/contact");
                            setShow(false);
                          }
                        : () => setShow(false)
                    }
                  >
                    {item.path.charAt(0).toUpperCase() + item.path.slice(1)}
                  </Link>
                );
              })}
            </nav>
          )}

          <button className="signin">sign in</button>
        </div>
      </header>
      {path === "/" ? (
        <section className="sub-header">
          {subheader.map((item, i) => {
            return (
              <section
                className="sub-items"
                key={i}
                onClick={
                  item.value === "reach us" ? () => navigate("/contact") : null
                }
                style={{ cursor: item.value === "reach us" && "pointer" }}
              >
                {item.icon}
                {item.value}
              </section>
            );
          })}
        </section>
      ) : null}
    </>
  );
});

export default Header;

import React from "react";
import { Element } from "react-scroll";
import "./service.css";

const Service = React.memo(() => {
  console.log("service componet");
  return (
    <Element name="service" className="service">
      <h3 className="heading">Our Service</h3>
      <div className="ser-container">
        <div className="ser-card">Web Development Services</div>
        <div className="ser-card">Ecommerce website design</div>
        <div className="ser-card">Mobile Application Development</div>
        <div className="ser-card">Digital Marketing</div>
        <div className="ser-card">Logo Design</div>
      </div>
    </Element>
  );
});

export default Service;

import { Foundation, Product, Service } from "../component";
import { Banner } from "../component/banner/banner";
import { Helmet } from "react-helmet";
import { BsCheckCircleFill } from "react-icons/bs";

const Home = () => {
  const data = [
    {
      content:
        "Our team of skilled developers and designers are well-versed in the latest web technologies and trends.",
    },
    {
      content:
        "We are constantly exploring new ideas and techniques to deliver unique and effective solutions.",
    },
    {
      content:
        "Rigorous testing and attention to detail ensure that every project we deliver is of the highest quality.",
    },
    {
      content:
        "Your satisfaction is our priority. We work closely with you to understand your needs and exceed your expectations.",
    },
    { content: "We provide solutions and free consultation." },
    {
      content:
        "We build Responsive Websites. It can be displayed correctly on various mobile devices.",
    },
    { content: "All website that we build must be SEO friendly." },
    {
      content:
        "Work in progress/Demo URL will be provided during the development. Therefore, customer can check for the result from time to time.",
    },
    {
      content:
        "We deliver based on your schedule We are open minded and always ready to accept new ideas and suggestions.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>VeeraWebTech</title>
        <meta
          name="description"
          content="Wants to grove your business through.we will help you.we are providing web application,mobile application and seo for your website"
        />
        <meta name="author" content="surya veeramani" />
        <meta
          name="keywords"
          content="VeeraWebTech,veerawebtech,veera web tech,Veera Web Tech,Veera Web tech,Veera web tech,it company in dharmapuri,software company in dharmapuri,dharmapuri"
        />
        <link rel="canonical" href="https://veerawebtech.com/" />
        <link rel="canonical" href="https://www.veerawebtech.com/" />
        <meta name="robots" content="index" />
      </Helmet>
      <main>
        <Banner />
        <Foundation />
        <Product />
        <Service />
        <div
          style={{
            background: "#e0f3f1",
            padding: "100px",
            position: "relative",
          }}
        >
          <section className="process">
            <h3>VeeraWebTech Software solutions</h3>
            <div className="process-grid">
              <p>
                Veerawebtech boasts a specialized team in web and software
                development, from programming to design. We deliver
                high-quality, tailored solutions that drive success
              </p>
            </div>
          </section>
          <section>
            <h3>Reasons To Choose</h3>
            {data.map((item, i) => {
              return (
                <p className="choose-us" key={i}>
                  <BsCheckCircleFill /> {item.content}
                </p>
              );
            })}
          </section>
        </div>
      </main>
    </>
  );
};

export default Home;

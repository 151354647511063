import React from "react";
import { Element } from "react-scroll";
import "./product.css";
import Lottie from "lottie-react";
import Webanimation from "../../asset/webanimation.json";
import Mobileanimation from "../../asset/mobileanimation.json";
const Product = React.memo(() => {
  const data = [
    {
      icon: Webanimation,
      path: "/folio/web",
      heading: "Web Folio",
      content: "",
    },
    {
      icon: Mobileanimation,
      path: "/folio/mobile",
      heading: "Mobile Folio",
      content: "",
    },
  ];
  return (
    <Element name="portfolio" className="portfolio">
      <h3 className="heading" style={{ color: "white" }}>
        Portfolio
      </h3>
      <div className="folio-sec">
        {data.map((item, i) => (
          <div
            style={{ width: "300px", height: "330px", textAlign: "center" }}
            key={i}
            className="folios"
          >
            <Lottie animationData={item.icon} loop={true} />
            <h3>{item.heading}</h3>
          </div>
        ))}
      </div>
    </Element>
  );
});

export default Product;

import React from "react";
import { Element } from "react-scroll";
import "./banner.css";
import { useNavigate } from "react-router-dom";
export const Banner = React.memo(() => {
  const navigate = useNavigate();
  return (
    <Element className="home" id="home">
      <h1 className="welcome text-glow-blue">WELCOME TO VEERAWEBTECH</h1>
      <h5 className="sub-text">Your Success, Our Innovation</h5>
      <p className="main-content">
        Veerawebtech is the leading Web Designing Company In Dharmapuri, Tamil
        Nadu. Initially, we create a awesome website, now we are extending our
        services to build Responsive & mobile-friendly website design, eCommerce
        Website Development, Landing Page Creation, WordPress Blog Creation,
        Website Migration, PSD to HTML Conversion, PSD to WordPress Conversion,
        Logo Design, Social Media Marketing, Poster Design, School Software, Gym
        Software, Billing Software, etc.. with the latest technologies.
      </p>
      <button className="readmore" onClick={() => navigate("/about")}>
        Read More
      </button>
    </Element>
  );
});

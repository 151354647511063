import React, { Suspense } from "react";
import { Home } from "./pages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer, Header } from "./component";
import { FaWhatsapp } from "react-icons/fa";

const Contact = React.lazy(() => import("./pages/contact/contact"));
const About = React.lazy(() => import("./pages/about/about"));
const Folio = React.lazy(() => import("./pages/folio/folio"));
const App = () => {
  return (
    <div style={{ position: "relative" }}>
      <Suspense fallback={<div>Loading..</div>}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/folio/:type" element={<Folio />} />
          </Routes>
          <Footer />
        </Router>
      </Suspense>
      <a href="https://wa.me/7305772645" target="blank">
        <FaWhatsapp className="whatapp" />
      </a>
    </div>
  );
};

export default App;

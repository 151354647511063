import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { FaLock } from "react-icons/fa";
import { FaServer } from "react-icons/fa";

export const data = [
  { path: "home", name: "Home" },
  { path: "portfolio", name: "product" },
  { path: "service", name: "Service" },
  // { path: "project", name: "Project" },
  // { path: "client", name: "Clients" },
  { path: "contact", name: "Clients" },
];

export const subheader = [
  { icon: <CiMail />, value: "info@veerawebtech.com" },
  { icon: <CiPhone />, value: "7305772645" },
  { icon: <IoLocationOutline />, value: "reach us" },
];

export const serviceData = [
  {
    icon: <FaLock />,
    title: "Free Domain",
    content:
      "All websites require a domain like .com, .in etc. We provide free domain of your choice for 1st year.",
  },
  {
    icon: <FaServer />,
    title: "Free Hosting",
    content:
      "Websites after development needs to be hosted to be viewed globally. Get 1st year web hosting absolutely free.",
  },
  {
    icon: <FaLock />,
    title: "Free SSL Certificate",
    content:
      "Security is a key concern today. We secure your websites with a free forever SSL certificate.",
  },
];

import React from "react";
import "./footer.css";
import { FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
const Footer = React.memo(() => {
  console.log("footer component");
  return (
    <footer className="footer">
      <div className="sec1">
        <h3 className="copyright">Copyright © 2024 | VeeraWebTech</h3>
        <a href="https://www.linkedin.com/in/veerawebtech-software-solution-771253310">
          {" "}
          <FaLinkedin />
        </a>

        <RiInstagramFill />
        <FaYoutube />
        <FaFacebook />
      </div>
      <address className="address">
        <p>3/18.Nagadhasampatti</p>
        <p> Bikkampatti (post)</p>
        <p>Dharmapuri-636813.</p>
      </address>
    </footer>
  );
});
export default Footer;
